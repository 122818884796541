import { alphaNum, maxLength, minLength, required } from 'vuelidate/lib/validators';
import { AbstractField } from '@/data/forms/fields/abstractField';

export class BarcodeField extends AbstractField {
  getField() {
    return {
      name: 'barcode',
      validations: {
        required,
        alphaNum,
        minLength: minLength(6),
        maxLength: maxLength(24),
      },
    };
  }
}
