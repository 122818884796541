<template>
  <Page
    content-class="home"
    logo
    class="tw-max-w-screen-sm">
    <Card key="home__tracking-form">
      <SkeletonLoader
        v-if="loadingFields"
        centered>
        <Heading
          v-skeleton="[20, 17]"
          level="2"
          class="text-center" />
        <FormField skeleton />
        <FormField skeleton />
        <FormField skeleton />
        <CButton v-skeleton="8" />
      </SkeletonLoader>

      <template v-else>
        <Heading
          v-t="{ path: 'home.tracking_heading', args: { shop: fields.company.name } }"
          level="2"
          class="text-center" />

        <SmoothReflow>
          <Alert
            v-if="barcodeError"
            variant="danger"
            title="form.barcode_not_found_heading"
            text="form.barcode_not_found" />
        </SmoothReflow>

        <ValidationForm
          :form="formFields"
          :v="$v"
          @submit="submit" />
      </template>
    </Card>
  </Page>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CButton from '@/components/CButton';
import Card from '@/components/Card';
import FormField from '@/components/forms/FormField';
import Heading from '@/components/Heading';
import Page from '@/views/Page';
import SkeletonLoader from '@/components/SkeletonLoader';
import ValidationForm from '@/components/forms/ValidationForm';
import { createHomeForm } from '@/data/forms/homeForm';
import { createValidationMixin } from '@/mixins/validation/createValidationMixin';

export default {
  name: 'Home',
  components: {
    Heading,
    Alert: () => import('@/components/Alert'),
    Page,
    CButton,
    FormField,
    Card,
    SkeletonLoader,
    ValidationForm,
  },

  mixins: [
    createValidationMixin(createHomeForm()),
  ],

  data() {
    return {
      selected_barcode: null,
      barcodeError: null,
    };
  },

  computed: {
    ...mapState([
      'loadingFields',
      'loadingTrackingInformation',
    ]),

    ...mapGetters(['fields']),
  },

  methods: {
    submit() {
      this.barcodeError = null;

      // to form submit after this
      const {
        cc,
        barcode,
        postal_code,
      } = this.formModel;

      this.findShipment({
        barcode: barcode.toUpperCase().trim(),
        postal_code: postal_code.replace(/\s/, '').toUpperCase().trim(),
        cc,
      });
    },

    /**
     * Use the entered values to search for a matching shipment.
     *
     * @param {Object} trackingInformation
     */
    async findShipment(trackingInformation) {
      try {
        await this.$store.dispatch('FETCH_TRACKING_INFORMATION', trackingInformation);
        this.showTrackingPage(trackingInformation);
      } catch (error) {
        this.barcodeError = error.data.message;
      }
    },

    /**
     * Navigate to /track-trace.
     *
     * @param {Object} params
     */
    showTrackingPage(params) {
      this.$router.push(`/track-trace/${params.barcode}/${params.postal_code}/${params.cc}`);
    },
  },
};
</script>
