import { BarcodeField } from '@/data/forms/fields/barcodeField';
import { CountryField } from '@/data/forms/fields/countryField';
import { PostalCodeField } from '@/data/forms/fields/postalCodeField';

export const createHomeForm = () => [
  new CountryField({ size: 'lg' }),
  new BarcodeField({ size: 'lg' }),
  new PostalCodeField({ size: 'lg' }),
  {
    type: 'submit',
    label: 'form.submit',
    size: 'lg',
    attributes: {
      class: 'text-center',
    },
  },
];
